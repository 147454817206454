@import "../core/mixins";
@import "../core/variables";

.details {
	margin-top: $spacing-8;

	@include media-down(md) {
		margin-top: $spacing-6;
	}

	&_content {
		display: flex;
		flex-direction: row;
		gap: $spacing-8;
		
		@include media-down(md) {
			display: block;
		}
	}

	.content {
		flex: 0 0 calc(30% - #{$spacing-5});

		@include media-down(md) {
			flex: 0 0 100%;
		}
	}

	&_wrapper {
		flex: 0 0 70%;
		display: flex;
		flex-direction: row;
		gap: $spacing-5;
		flex-wrap: wrap;

		@include media-down(md) {
			flex: 0 0 100%;
			gap: $spacing-3;
			margin-top: $spacing-5;
		}
	}

	&_item {
		flex: 0 0 calc(33.333% - #{$spacing-5});
		padding: $spacing-8;
		background: $ui-grey-03;
		text-align: center;
		color: $ui-black;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: $spacing-4;
		aspect-ratio: 1 / 1;

		@include media-down(lg) {
			padding: $spacing-4;
			flex: 0 0 calc(50% - #{$spacing-5});
		}

		@include media-down(md) {
			padding: $spacing-4;
			flex: 0 0 100%;
			aspect-ratio: 2/1;
			gap: $spacing-2;
		}



		a {
			display: block;
		}

		h5 {
			text-transform: uppercase;
		}

		.content {
			flex: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			a {
				display: inline-block;
				text-decoration: underline;
			}
		}
	}

	&._secondary {
		margin-top: $spacing-8;
		padding-top: $spacing-8;
		border-top: 1px solid $ui-grey-03;

		@include media-down(md) {
			margin-top: $spacing-6;
			padding-top: $spacing-6;
		}
	}
}


@import "mixins";
@import "variables";

//DISPLAY
%display-1 {
	@include font($style: headingsMedium);
	@include fluid(40, 200);
	line-height: 120%;

	@include media-up(sm) {
		line-height: 100%;
	}
}

%display-2 {
	@include font($style: headingsMedium);
	@include fluid(40, 128);
	line-height: 100%;
}

%display-3 {
	@include font($style: headingsMedium);
	@include fluid(40, 96);
	line-height: 110%;

	@include media-up(sm) {
		line-height: 100%;
	}
}

%display-4 {
	@include font($style: headingsMedium);
	@include fluid(28, 64);
	line-height: 110%;

	@include media-up(sm) {
		line-height: 100%;
	}
}

%display-5 {
	@include font($style: headingsMedium);
	@include fluid(32, 40);
	line-height: 120%;
}

%display-6 {
	@include font($style: headingsMedium);
	@include fluid(24, 40);
	line-height: 120%;
}

%display-7 {
	@include font($style: headingsMedium);
	@include fluid(20, 32);
	line-height: 120%;
}

%display-8 {
	@include font($style: headingsMedium);
	@include fluid(18, 24);
	line-height: 120%;
}

%display-9 {
	@include font($style: regular);
	@include fluid(18, 24);
	line-height: 120%;
}

//BODY
%body-1 {
	@include font($style: regular);
	@include fluid(16, 16);
	line-height: 150%;
}

%body-2 {
	@include font($style: regular);
	@include fluid(14, 16);
	line-height: 150%;
}

%body-3 {
	@include font($style: regular);
	font-size: 14px;
	line-height: 150%;
}

//LABEL
%label-1 {
	@include font($style: regular);
	@include fluid(18, 20);
	line-height: 120%;
}

%label-2 {
	@include font($style: regular);
	@include fluid(16, 18);
	font-weight: 400;
	line-height: 120%;
}

%label-3 {
	@include font($style: headingsMedium);
	@include fluid(16, 18);
	line-height: 120%;
}

%label-4 {
	@include font($style: regular);
	@include fluid(14, 16);
	line-height: 120%;
}

%label-5 {
	@include font($style: regular);
	font-size: 14px;
	line-height: 120%;
}

%label-6 {
	@include font($style: regular);
	font-size: 12px;
	line-height: 120%;
}

//CTA
%cta-1 {
	@include font($style: regular);
	@include fluid(18, 20);
	line-height: 100%;
}

%cta-2 {
	@include font($style: headingsMedium);
	@include fluid(14, 16);
	line-height: 100%;
}

%cta-3 {
	@include font($style: regular);
	@include fluid(14, 16);
	line-height: 100%;
}

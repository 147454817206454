@import "../core/mixins";
@import "../core/variables";

.footer {
    background: $ui-black;
	padding: $spacing-5 0;
	margin-top: $spacing-8;

	&_content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: $spacing-5;

		@include media-down(sm) {
			flex-direction: column;
			text-align: center;
		}
	}
}

@use 'sass:math';

@mixin font($style: regular) {
    font-style: normal;
    font-display: swap;

    @if $style==regular {
        font-family: 'Montserrat';
		font-weight: 400;
    }

    @if $style==headingsRegular {
        font-family: 'Roboto', sans-serif;
		font-weight: 400;
    }

    @if $style==headingsMedium {
        font-family: 'Roboto', sans-serif;
		font-weight: 600;
    }
}

@mixin media-hover() {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }

    &:focus-visible {
        @content;
    }
}

@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @return math.div($pxval, $baseFontSize) * 1rem;
}

@mixin fluid($min: 48, $max: 96, $property: font-size, $base: 1920) {
	#{$property}: Max($min * 1px, Min($max * 1px, calc($max / $base * 100vw)));
}

@mixin spacing($key, $property: padding) {
	@if $key==14 { @include fluid(160, 256, $property); }
	@if $key==13 { @include fluid(156, 192, $property); }
	@if $key==12 { @include fluid(128, 160, $property); }
	@if $key==11 { @include fluid(96, 128, $property); }
	@if $key==10 { @include fluid(80, 96, $property); }
	@if $key==9 { @include fluid(40, 80, $property); }
	@if $key==8 { @include fluid(32, 64, $property); }
	@if $key==7 { @include fluid(32, 48, $property); }
	@if $key==6 { #{$property}: 40px; }
	@if $key==5 { #{$property}: 32px; }
	@if $key==4 { #{$property}: 24px; }
	@if $key==3 { #{$property}: 16px; }
	@if $key==2 { #{$property}: 12px; }
	@if $key==1 { #{$property}: 8px; }
	@if $key==0 { #{$property}: 4px; }
}

// @mixin line($default, $hover) {
// 	background:
// 	linear-gradient(to right, $default, $default),
// 	linear-gradient(to right, $hover, $hover);
// 	background-size: 100% 0.1em, 0 0.1em;
// 	background-position: 0 50%, 0 100%;
// 	background-repeat: no-repeat;
// 	transition: .4s $smooth all;
// 	display: inline;

// 	&:hover,
// 	&:focus {
// 		background-size: 0 0.1em, 100% 0.1em;
// 	}
// }

@import "mixins";
@import "variables";

//typography
body,
html {
    @extend %body-1;
	color: $ui-black;
	line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font($style:headingsMedium);
    padding: 0;
    margin: 0;
}

._upper { text-transform: uppercase}

._display-1 { @extend %display-1; }
._display-2 { @extend %display-2; }
._display-3 { @extend %display-3; }
._display-4 { @extend %display-4; }
._display-5 { @extend %display-5; }
._display-6 { @extend %display-6; }
._display-7 { @extend %display-7; }
._display-8 { @extend %display-8; }
._display-9 { @extend %display-9; }

._body-1 { @extend %body-1; }
._body-2 { @extend %body-2; }
._body-3 { @extend %body-3; }

._label-1 { @extend %label-1; }
._label-2 { @extend %label-2; }
._label-3 { @extend %label-3; }
._label-4 { @extend %label-4; }
._label-5 { @extend %label-5; }
._label-6 { @extend %label-6; }

._cta-1 { @extend %cta-1; }
._cta-2 { @extend %cta-2; }
._cta-3 { @extend %cta-3; }

._dot {
	list-style: none;
	position: relative;
	padding: 0 0 0 0.6em;
	margin: 0 0 0.5em 8px;

	&::before {
		position: absolute;
		content: '\2022';
		color: $ui-black;
		top: 0;
		left: -0.5rem;
		text-align: center;
		font-size: 1.2em;
		line-height: 1.15;
		transition: all .4s $smooth;
	}

	@include media-hover {
		&::before {
			left: -0.2rem;
		}
	}

	span {
		margin-right: $spacing-2;
	}
}

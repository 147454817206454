//css vars
:root {
}

//gutter
$spacing-12: 160px;
$spacing-11: 128px;
$spacing-10: 96px;
$spacing-9:  80px;
$spacing-8:  64px;
$spacing-7:  48px;
$spacing-6:  40px;
$spacing-5:  32px;
$spacing-4:  24px;
$spacing-3:  16px;
$spacing-2:  12px;
$spacing-1:  8px;
$spacing-0:  4px;

//max width
$maxWidth: 1920;

//font size
$font-main: 16px;
$baseFontSize: 16;

//colors
$ui-blue:    #003B5C;
$ui-black:   #333333;
$ui-white:   #ffffff;
$ui-grey: 	 #6A6969;
$ui-grey-01: #999999;
$ui-grey-02: #CDCDCD;
$ui-grey-03: #E8E8E8;
$ui-grey-04: #F6F6F6;
$ui-grey-05: #FBFBFB;

$status-error:   #9D0000;
$status-success: #00875E;
$status-warning: #CB9200;
$status-neutral: #A3A3A3;

//elements
$header-height: 109px;
$header-height-m: 89px;

//animations
$expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$smooth: cubic-bezier(0, 0.97, 0.43, 1);

//grid
$grid-columns: 12 !default;
$grid-gutter-widths: (
	xxs: 8px,
	xs: 8px,
	sm: 8px,
	md: 20px,
	lg: 20px,
	xl: 20px) !default;
$grid-breakpoints: (
	xxs: 0,
	xs: 576px,
	sm: 768px,
	md: 1024px,
	lg: 1440px,
	xl: 1920px) !default;
$container-max-widths: (
	xxs: calc(100% - 24px),
	xs: calc(100% - 24px),
	sm: calc(100% - 24px),
	md: calc(100% - 60px),
	lg: calc(100% - 60px),
	xl: calc(100% - 60px)) !default;
$container-fluid-max-widths: (
	xxs: calc(100% - 24px),
	xs: calc(100% - 24px),
	sm: calc(100% - 24px),
	md: calc(100% - 60px),
	lg: calc(100% - 60px),
	xl: calc(100% - 60px))  !default;

@use 'sass:math';
@import "../core/mixins";
@import "../core/variables";

.media {
	position: relative;

	img,
	video,
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;
		object-position: 50% 50%;
		will-change: transform;
	}

	&:before {
		content: '';
		display: block;
	}

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		background: $ui-grey-04;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}

	img,
	video {
		opacity: 0;
		transition: .2s $smooth opacity;

		&._media-loaded {
			opacity: 1;
		}
	}

	// video {
	// 	opacity: 1;
	// }

	&._1-1 {
		&:before {
			padding-bottom: math.percentage(math.div(1, 1));
		}
	}

	&._1-2 {
		&:before {
			padding-bottom: math.percentage(math.div(2, 1));
		}
	}

	&._2-1 {
		&:before {
			padding-bottom: math.percentage(math.div(1, 2));
		}
	}

	&._3-2 {
		&:before {
			padding-bottom: math.percentage(math.div(2, 3));
		}
	}

	&._3-4 {
		&:before {
			padding-bottom: math.percentage(math.div(4, 3));
		}
	}

	&._16-9 {
		&:before {
			padding-bottom: math.percentage(math.div(9, 16));
		}
	}

	&._h100 {
		height: 100%;

		&:before {
			height: 100%;
		}
	}
}

.motion {
	position: relative;
	overflow: hidden;
	transition: .8s;
	will-change: transform;

	&._fade {
		opacity: 0;
		transform: translateY(30px);
	}

	&._scale {
		opacity: 0;
		transform: scale(1.3);
	}
}

._outboard-lft {
	position: relative;
	width: calc(100% + $spacing-6);
	left: -#{$spacing-6};

	@include media-down(sm) {
		width: calc(100% + $spacing-3);
		left: -#{$spacing-3};
	}

	figcaption {
		margin-left: $spacing-6;

		@include media-down(sm) {
			margin-left: $spacing-3;
		}
	}
}

._outboard-rgt {
	position: relative;
	width: calc(100% + $spacing-6);
	left: 0;

	@include media-down(sm) {
		width: calc(100% + $spacing-3);
		left: 0;
	}

	figcaption {
		margin-right: $spacing-6;

		@include media-down(sm) {
			margin-right: $spacing-3;
		}
	}
}

@import "../core/mixins";
@import "../core/variables";

.nav {

	&__wrapper {
		display: flex;
		flex-direction: row;
		position: relative;
	}

	&__item,
	&__btn {
		@extend %label-4;
		color: $ui-black;
	}

	&__item {
		@include fluid(16, 32, padding-left);
		@include fluid(16, 32, padding-right);

		a {
			color: $ui-black;
		}
	}

	&__current {
		position: absolute;
		height: 100%;
		top: 0;
		z-index: -1;
		transition: all .5s ease 0s;
		border-radius: 8px;
		display: none;
	}
}


//MOBILE
@include media-down(sm) {
	.nav {

		&__wrapper {
			flex-direction: column;
			padding: 72px $spacing-3 0 $spacing-3;
		}

		&__item,
		&__btn {
			font-family: 'sangbleu_empireregular' !important;
			font-weight: 400 !important;
			font-size: 40px !important;
			line-height: 120% !important;
			letter-spacing: -0.8px !important;
			padding: 0 !important;
			color: $ui-black !important;

			& > * {
				color: $ui-black;
			}
		}

		&__item {
			margin-top: $spacing-5;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	.nav__item.active {
		overflow: hidden;

		.subnav {
			transform: translateX(0);
		}
	}
}

@import "../core/mixins";
@import "../core/variables";

.hero {
    overflow-x: hidden;
	
	&_content {
		position: relative;
		display: flex;
		gap: $spacing-5;
		align-items: center;
		justify-content: space-between;

		@include media-down(sm) {
			display: block;
		}
	}

	&_item {
		flex: 0 0 calc(50% - #{$spacing-5});
		max-width: calc(50% - #{$spacing-5});

		@include media-down(sm) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-bottom: $spacing-6;
		}

		&._bg {
			padding: 0 $spacing-8;
			
			@include media-down(md) {
				padding: $spacing-4 0;
			}
			
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: -100px;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: $ui-black;

				@include media-down(sm) {
					width: calc(100% + 200px);
				}
			}
		}
	}
	
	&_swiper {
		height: auto;
		width: 100%;

		img {
			max-width: 100%;
		}
	}
}
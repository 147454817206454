@import "../core/mixins";
@import "../core/variables";

.header {
    position: fixed;
	z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
	height: $header-height;
	padding: 0 $spacing-6;
	background: $ui-white;
    display: flex;
    align-items: center;
	flex-direction: row;
	justify-content: space-between;
	// background: rgb(190, 190, 190);

	@include media-down(sm) {
		padding: 0 $spacing-4;
		height: $header-height-m;
	}

	&_logo {
		display: block;
		flex: 0 0 20%;

		@include media-down(sm) {
			flex: 0 0 50%;
		}

		a {
			display: block;
		}

		svg{
			width: 180px;
			height: 29px;

			@include media-down(sm) {
				width: 128px;
				height: 21px;
			}
		}
	}

	&_contacts {
		@include media-down(sm) {
			display: none;
		}
	}

}


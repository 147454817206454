@import "../core/mixins";
@import "../core/variables";

.section {
    position: relative;
    @include spacing(14, padding-top);

	&:first-child {
		padding-top: 0;
	}

	&__title._hero {
		@include spacing(13, padding-top);

		@include media-down(sm) {
			padding-top: $spacing-8;
		}
	}
}

.section__title + .section__wrapper {
	@include spacing(8, padding-top);
}

.header + section {
    padding-top: 0;
}

@import "../core/mixins";
@import "../core/variables";

[link-to],
button,
a {
	cursor: pointer;
	color: $ui-black;

	&,
	&:hover,
	&:active,
	&:focus,
	&:visited {
		text-decoration: none;
	}
}

button {
	background: transparent;
	padding: 0;
	border: none;
	outline: 0;
	-webkit-appearance: none;
	text-decoration: none;

	&:focus,
	&:active {
		outline: none;
	}
}

[type=reset],
[type=submit],
button,
html [type=button] {
	-webkit-appearance: none;
}

.btn {
	text-decoration: none;

	&._border {
		position: relative;
		border: 1px solid $ui-black;
		padding: 12px 16px 12px 16px;
		border-radius: 60px;
		display: inline-block;
		transition: all .6s $smooth;

		@include media-hover() {
			background: $ui-black;
			color: $ui-white;
		}

		@include media-down(sm) {
			text-align: center;
		}

		&._light {
			color: $ui-white;
			border-color: $ui-white;

			@include media-hover() {
				background: $ui-white;
				color: $ui-black;
			}
		}
	}

	&._border-full {
		@extend ._border;
		background: $ui-black;
		color: $ui-white;

		.ico {

			svg {
				color: $ui-black;
			}

			&:after {
				background: $ui-white;
			}
		}
	}
}

[data-magnet-btn] {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: fit-content;
	border-radius: 60px;
	z-index: 2;

	.cta__item {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: 60px;
		overflow: clip;
		overflow-clip-margin: 1px;
		min-width: 260px;

		> a,
		> button,
		> div {
			width: 100%;
			height: 100%;
			border-radius: 60px;
			background-color: $ui-white;
			padding: 12px 60px 12px 16px;
			text-decoration: none;
			text-align: left;
			cursor: pointer;
		}
	}

	.btn__text {
		position: relative;
		z-index: 1;
		transition: all 0.25s;
	}

	.btn__hover {
		position: absolute;
		display: block;
		width: 0px;
		height: 0px;
		border-radius: 50%;
		background-color: $ui-black;
		transform: translate(-50%, -50%);
		transition: width 0.5s cubic-bezier(0.25, 1, 0.25, 1),
					height 0.5s cubic-bezier(0.25, 1, 0.25, 1);
		z-index: 0;
	}

	.btn__outline {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 60px;
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: $ui-black;
		z-index: 2;
	}

	.ico {
		position: absolute;
		top: 50%;
		right: $spacing-3;
		color: $ui-white;
		transform: translateY(-50%);
		width: 10px;
		height: 10px;
		transition: .4s $smooth all;
		transform-origin: center;

		svg {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 0;
			height: 0;
			color: $ui-white;
			transition: .4s $smooth all;
			transform-origin: center;
		}

		&:after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: $ui-black;
			border-radius: 50%;
			width: 10px;
			height: 10px;
			transition: .4s $smooth all;
			transform-origin: center;
		}
	}

	.btn {
		@include media-hover() {

			.btn__text {
				color: $ui-white;
			}

			.ico {
				width: 32px;
				height: 32px;
				right: 4px;

				svg {
					width: 20px;
					height: 20px;
					color: $ui-black;
				}

				&:after {
					width: 30px;
					height: 30px;
					background: $ui-white;
				}
			}
		}
	}

	&._full {

		.cta__item {

			> a,
			> button,
			> div {
				background-color: $ui-black;
			}
		}

		.btn__text {
			color: $ui-white;
		}

		.btn__outline {
			border-color: $ui-black;
		}

		.btn__hover {
			background-color: $ui-white;
		}

		.ico {
			color: $ui-white;

			svg {
				color: $ui-white;
			}

			&:after {
				background: $ui-white;
			}
		}

		.btn {
			@include media-hover() {

				.btn__text {
					color: $ui-black;
				}

				.ico {

					svg {
						color: $ui-white;
					}

					&:after {
						background: $ui-black;
					}
				}
			}
		}
	}

	&._transparent {
		.cta__item {
			.btn {
				background: transparent;
			}
		}

		.btn__outline {
			border-color: $ui-white;
		}
	}
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
	cursor: pointer;
}

.cta {
	display: flex;
	flex-wrap: wrap;
	gap: $spacing-3;

	@include media-down(sm) {
		flex-direction: column;
	}
}

._label-4 {

	.btn._line:after,
	.btn._line-alt:after,
	&.btn._line:after,
	&.btn._line-alt:after {
		bottom: 0;
	}
}

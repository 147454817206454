@import "mixins";
@import "variables";

*,
*::before,
*::after {
	box-sizing: border-box;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
	line-height: 1.2;
	-webkit-text-size-adjust: 100%;
}

html,
body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0 auto;
	@include hideScrollbar();
}

.wrapper {
	background: $ui-white;
	max-width: 1920px;
	margin: 0 auto;
	position: relative;
	z-index: 1;
	left: 0;
	right: 0;
	min-height: 100vh;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
	overflow: hidden;
	transition: transform .6s $smooth;
}

.main {
    margin-top: $header-height;

    @include media-down(sm) {
        margin-top: $header-height-m;
    }

	&__wrapper {
		position: relative;
		z-index: 1;
		background: white;
		min-height: 100vh;
		@include spacing(12, padding-bottom);

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			box-shadow: 0px 9px 22px 5px rgb(0, 0, 0, 0.15);
		}
	}
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

// [data-module] {
// 	overflow-x: hidden;
//     overflow-y: auto;
// }

// [data-module] {
// 	opacity: 0;
// 	transition: opacity .3s ease-in-out .1s;

// 	&._loaded {
// 		opacity: 1;
// 	}
// }

.row {
	&._ai-end {
		align-items: flex-end;
	}

	&._ai-center {
		align-items: center;
	}
}

svg {
	display: block;
}

.splitting .word {
	white-space: nowrap;
}

.char-wrap {
	display: inline-block;
	position: relative;
	overflow: hidden;
}

.screen-reader-response {
	display: none;
}

.lightbox {
	position: fixed;
	z-index: 999;
	top: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	background: $ui-white;
	transition: top .8s $smooth;
	opacity: 1;

	&._loaded {
		top: 0;
	}
}

.zoom {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

[data-gallery] {
	cursor: pointer;
}

.border {
	width: 0;
	height: 1px;
	background: $ui-black;
}

body {
	//block all the animations on resize
	&._resizing * {
		transition: none !important;

		&:before,
		&:after {
			transition: none !important;
		}
	}

	//hide cursor
	&._hidden-cursor {
		[data-zoom-button] {
			.card__wrapper,
			.media img {
				cursor: none !important;
				cursor: -moz-not-allowed;
				cursor: -webkit-not-allowed;
			}
		}
	}

	//DEBUG CLASSES
	&.borders-off {
		.border {
			width: 100%;
		}
	}

	&.fadein-off {
		.motion._fade {
			translate: none;
			rotate: none;
			scale: none;
			transform: translate(0px, 0px);
			opacity: 1;
			visibility: inherit;
			transition: none;
		}
	}
}

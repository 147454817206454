@mixin hideScrollbar() {

	&::-webkit-scrollbar,
	&::-webkit-scrollbar-button,
	&::-webkit-scrollbar-button:hover,
	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-track {
		display: none;
	}
}

@mixin scrollbar() {

	&::-webkit-scrollbar {
		width: 6px;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $ui-grey-01;
		box-shadow: none;
		border-radius: 4px;

		&:hover {
			background-color: $ui-grey-02;
		}
	}

	&::-webkit-scrollbar-track {
		background-color:#E8E8E8;
		border-radius: 4px;

		&:hover {
			background-color: transparent;
		}
	}
}

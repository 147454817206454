@import "../core/mixins";
@import "../core/variables";

.content {
    color: $ui-black;

	a {
		color: $ui-black;

		&:after {
			background: $ui-black;
		}
	}

    & > * {
        @include spacing(5, margin-top);
		color: $ui-black;

        &:first-child {
            margin-top: 0;
        }
    }

	&._mtop-12 > * { @include spacing(12, margin-top) }
	&._mtop-11 > * { @include spacing(11, margin-top) }
	&._mtop-10 > * { @include spacing(10, margin-top) }
	&._mtop-9 > * { @include spacing(9, margin-top) }
	&._mtop-8 > * { @include spacing(8, margin-top) }
	&._mtop-7 > * { @include spacing(7, margin-top) }
	&._mtop-6 > * { margin-top: $spacing-6 }
	&._mtop-5 > * { margin-top: $spacing-5 }
	&._mtop-4 > * { margin-top: $spacing-4 }
	&._mtop-3 > * { margin-top: $spacing-3 }
	&._mtop-2 > * { margin-top: $spacing-2 }
	&._mtop-1 > * { margin-top: $spacing-1 }
	&._mtop-0 > * { margin-top: $spacing-0 }

	&._mtop-12,
	&._mtop-11,
	&._mtop-10,
	&._mtop-9,
	&._mtop-8,
	&._mtop-7,
	&._mtop-6,
	&._mtop-5,
	&._mtop-4,
	&._mtop-3,
	&._mtop-2,
	&._mtop-1,
	&._mtop-0 {
		> *:first-child {
			margin-top: 0;
		}
	}

	&._mtop-12 .text > * { @include spacing(12, margin-top) }
	&._mtop-11 .text > * { @include spacing(11, margin-top) }
	&._mtop-10 .text > * { @include spacing(10, margin-top) }
	&._mtop-9 .text > * { @include spacing(9, margin-top) }
	&._mtop-8 .text > * { @include spacing(8, margin-top) }
	&._mtop-7 .text > * { @include spacing(7, margin-top) }
	&._mtop-6 .text > * { margin-top: $spacing-6 }
	&._mtop-5 .text > * { margin-top: $spacing-5 }
	&._mtop-4 .text > * { margin-top: $spacing-4 }
	&._mtop-3 .text > * { margin-top: $spacing-3 }
	&._mtop-2 .text > * { margin-top: $spacing-2 }
	&._mtop-1 .text > * { margin-top: $spacing-1 }
	&._mtop-0 .text > * { margin-top: $spacing-0 }

	&._mtop-12 .text,
	&._mtop-11 .text,
	&._mtop-10 .text,
	&._mtop-9 .text,
	&._mtop-8 .text,
	&._mtop-7 .text,
	&._mtop-6 .text,
	&._mtop-5 .text,
	&._mtop-4 .text,
	&._mtop-3 .text,
	&._mtop-2 .text,
	&._mtop-1 .text,
	&._mtop-0 .text {
		> *:first-child {
			margin-top: 0;
		}
	}
		
	&._light {
		color: $ui-white;

		> * {
			color: $ui-white;
		}

		a {
			color: $ui-white;

			&:after {
				background: $ui-white;
			}
		}
	}

	&._right {
		text-align: right;
	}

	&._center {
		text-align: center;
	}

}

.text {

	& > * {
		@include spacing(5, margin-top);

		&:first-child {
			margin-top: 0;
		}
	}

	.btn._line {
		&:after {
			bottom: 2px;
		}
	}
}
